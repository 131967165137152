import React from "react"
import Image from "gatsby-image";
import "../../styles/offer.scss"
import OfferHeader from "../../components/OfferHeader";
import OfferItem from "../../components/OfferItem"
import Realizations from "../../components/Realizations"
import SEO from "../../components/seo"
import { graphql } from "gatsby"

const dataSaddler = {
    header: "Preferujesz naprawę zamiast zakup nowego? Przynieś produkt do nas",
    text: "W pracy wykorzystujemy najwyższej jakości materiały oraz narzędzia. Nasze wyroby na pewno zaspokoją państwa oczekiwania. Dzięki wieloletniemu doświadczeniu mogą być Państwo pewni jakości naszych napraw, ponieważ zadowolenie klienta jest dla najważniejsze. ",
    link: "prices",
    headerItem1: "Wytwarzamy, konserwujemy oraz naprawiamy",
    descItem1: "Do standardowych wyrobów rymarskich należą: uprzęże, siodła, akcesoria do jazdy konnej, sprzęt myśliwski, artykuły turystyczne i sportowe, artykuły ochronne, pasy i paski, kabury na broń czy futerały na instrumenty muzyczne.",
    headerItem2: "Gwarantujemy najlepsze ceny świadczonych usług",
    descItem2: "Dokładamy wszelkich starań by oferta naszego zakładu wyróżniała się wysoką jakością oraz atrakcyjnymi cenami. Prowadzimy usługi z pełnym zaangażowaniem.",
    realizationHeader: "Nasze przykładowe realizacje",
    relazationDesc: "Realizujemy różnorodne prace krawieckie. Zapewniamy pełen profesjonalizm, nasze wieloletnie doświadczenie w branży pozwala nam wykonywać usługi solidnie, szybko i za rozsądną cenę."

}


const description = "Konserwacja i naprawa uprzęży, siodeł i innych akcesoriów do jazdy konnej. Naprawa i szycie artykułów turystycznych oraz futerałów na instrumenty. Konin"
const keywords = ["rymarz konin", "usługi rymarskie"]
const SaddleryOfferPage = ({ data }) => (
    <>
        <SEO
            title="Oferta | Rymarz"
            description={description}
            keywords={keywords}
        />
        <section className="offerPage container">
            <OfferHeader
                headerOffer={dataSaddler.header}
                textOffer={dataSaddler.text}
            />

            <div className="imgContainer">


                <div className="horse"><Image fluid={data.horse1.childImageSharp.fluid} /></div>
                <div className="horse"><Image fluid={data.horse2.childImageSharp.fluid} /></div>


            </div>

            <Realizations
                header={dataSaddler.realizationHeader}
                desc={dataSaddler.relazationDesc}
                images={data.containers}
                linkPath={"galeria/pozostale"}
            />
            <div className="offerItemsContainer">
                <OfferItem
                    headerItem={dataSaddler.headerItem1}
                    descItem={dataSaddler.descItem1}
                    img={data.horse3.childImageSharp.fluid}
                />
                <OfferItem
                    headerItem={dataSaddler.headerItem2}
                    descItem={dataSaddler.descItem2}
                    img={data.horse4.childImageSharp.fluid}
                    leftImg
                />
            </div>

        </section>
    </>
)

export const query = graphql`
  {
    horse1: file(name: {eq: "sadd_horse1"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    horse2: file(name: {eq: "sadd_horse2"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    horse3: file(name: {eq: "sadd_horse3"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    horse4: file(name: {eq: "sadd_horse4"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    containers: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "saddlery/realizations"}}) {
    edges {
      node {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  },

  }
`

export default SaddleryOfferPage

import React from 'react';
import "../styles/realizationImages.scss"
import Image from "gatsby-image";





const RealizationsImages = props => (
    <div className="imgRelContainer">
        {Object.values(props.images)[0].map((item, i) =>
            <div key={i} className="img">
                <Image fluid={item.node.childImageSharp.fluid} />
            </div>)
        }
    </div>

)

export default RealizationsImages;
import React from 'react';
import { Link } from "gatsby"
import "../styles/linkButton.scss"
import scissors from "../images/main/scissors.svg"


const LinkButton = props => (

    <Link className="linkButton" to={`/${props.linkPath}`}  >
        <p>{props.text ? props.text : "Zobacz więcej"}</p>
        <img src={scissors} alt="nożyczki" />
    </Link>



)

export default LinkButton;
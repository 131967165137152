import React from 'react';
import "../styles/realization.scss"
import LinkButton from "./LinkButton"
import RealizationsImages from "./RealizationImages"





const Realizations = props => (

    <div className="realizations">
        <div className="header">{props.header}</div>
        <div className="desc">
            {props.desc}
        </div>
        <RealizationsImages
            images={props.images}
        />
        <div className="linkContainer">
            <LinkButton
                linkPath={props.linkPath}
            />
        </div>


    </div>



)

export default Realizations;
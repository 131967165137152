import React from 'react';
import "../styles/offerHeader.scss"
import LinkButton from "./LinkButton"



const OfferHeader = props => (

    <div className="offerHeader">
        <h2>{props.headerOffer}</h2>
        <div className="descContainer">
            <p className="descText">{props.textOffer}  </p>
            {props.linkOffer ? <LinkButton linkPath={props.linkOffer} text="cennik" /> : null}
        </div>

    </div>



)

export default OfferHeader;
import React from 'react';
import "../styles/offerItem.scss"
import Image from "gatsby-image";




const OfferItem = props => (

    <div className={props.leftImg ? "offerItem reverse" : "offerItem"}>

        <div className="textContainer">
            {props.line ? <div className={props.line}></div> : null}
            <h3 className="header">{props.headerItem} </h3>
            <p className="desc"> {props.descItem}
            </p>
        </div>
        {props.onlySvg ? <div className="imgItemContainer">
            <img src={props.onlySvg} alt="" />
        </div> :
            <div className="imgItemContainer">
                <Image fluid={props.img} />
            </div>
        }


    </div>



)

export default OfferItem;